import {makeAutoObservable} from 'mobx';

export default class LoaderStore {

    visible: boolean = false;
    title: string = '';
    message: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    setStatus(status: boolean) {
        this.visible = status;
    }

    show(title: string, message: string = '') {
        this.title = title;
        this.message = message;
        this.setStatus(true);
    }

}
