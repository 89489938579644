import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import DeleteServiceModal from '../components/modal/delete-service-modal';
import ServiceDashboard from '../components/services/service-dashboard';
import {useStore} from '../store/store';
import StatusLoader from '../components/status/status-loader';

export default observer(function Customer() {


    const { serviceStore, loaderStore } = useStore();


    useEffect(() => {
        loaderStore.show('Loading services', 'Please wait while we load your services');
        serviceStore.loadServices().then(() => {
            loaderStore.setStatus(false);
        });
    }, [serviceStore, loaderStore]);

    return (
        <React.Fragment>
            <StatusLoader />
            <DeleteServiceModal/>
            <div>
                <h1>Service</h1>
                <ServiceDashboard/>
            </div>
        </React.Fragment>

    );
});
