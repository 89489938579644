import axios, { AxiosResponse } from 'axios';
import ICustomer from '../entities/customer';
import ICustomerFilter from '../entities/http/customer-filter';
import IService from '../entities/service';

//axios.defaults.baseURL = 'https://localhost:7244/api';
axios.defaults.baseURL = 'https://servercsharp.apispot.net/api';

const responseBody = (response: AxiosResponse) => response.data;

const request = {
    get: (url: string, filters: {} = {}) =>  {
        const param = new URLSearchParams(filters);
       return axios.get(url, { params: param }).then(responseBody)

    },
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
};

const customer = {
    list: () => request.get('/Customer'),
    search: (filters: Partial<ICustomerFilter> = {}) => request.get('/Customer/search', filters),
    create: (customer: ICustomer) => request.post('/Customer', customer),
    update: (customer: ICustomer) => request.put('/Customer', customer),
    delete: (customerId: number) => request.delete(`/Customer/${customerId}`)
}


const service = {
    list: () => request.get('/Service'),
    search: (filters: Partial<IService> = {}) => request.get('/Service/search', filters),
    create: (service: IService) => request.post('/Service', service),
    update: (service: IService) => request.put('/Service', service),
    delete: (serviceId: string) => request.delete(`/Service/${serviceId}`)
}

const country = {
    listStateByCountry: (countyCode: string) => request.get(`/Country/${countyCode}/states`),
}

const api = {
    axios, customer, country, service
}

export default api;
