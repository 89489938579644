import {action, makeAutoObservable, makeObservable, observable, runInAction} from 'mobx';
import ICustomer from '../entities/customer';
import api from '../api/api';
import IState from '../entities/state';
import ICustomerFilter from '../entities/http/customer-filter';

export default class CustomerStore {

    title = 'Hello World';
    editCustomer: boolean = false;
    isListLoaded: boolean = false;
    deleteModalVisible: boolean = false;
    customers: ICustomer[] = [];
    selectedCustomer: ICustomer | null = null;
    countryStates: IState[] = [];
    errorMessage: string = '';
    constructor() {

        makeAutoObservable(this);

        // Opción 2
        /*
        makeObservable(this, {
            title: observable,
            setTitle: action
        });
         */
    }


    addError(error: string) {
        this.errorMessage = error;
    }


    deleteCustomerEvent = (customer: ICustomer) => {
        this.deleteModalVisible = true;
        this.selectedCustomer = customer;
    }
    confirmDeleteCustomerEvent = async () => {

            try {
                const response = await api.customer.delete(this.selectedCustomer!.id);
                if (response.data) {
                    let customerIndex = this.customers.findIndex((customer) => customer.id === this.selectedCustomer!.id );
                    if (customerIndex !== -1) {
                        this.customers.splice(customerIndex, 1);
                    }
                    this.cancelDeleteCustomerEvent();
                } else {
                    this.errorMessage = 'An error has occurred please try again later';
                }
            } catch (ex) {
                this.errorMessage = 'An error has occurred please try again later';
            }


    }
    cancelDeleteCustomerEvent = () => {
        runInAction(() => {
            this.deleteModalVisible = false;
            this.selectedCustomer = null;
            this.errorMessage = '';
        });
    }


    setTitle = () => {
        this.title = this.title + '!';
    }

    loadStates = async (countryCode: string = 'MX') => {
        try {
            const response = await api.country.listStateByCountry(countryCode);
        } catch (ex) {
            console.error(ex);
        }
    }


    changeLoader = (value: boolean) => {
        this.isListLoaded = value;
    }

    loadCustomer = async (filters: Partial<ICustomerFilter> = {}) => {
        try {
            const responseCustomer = await api.customer.search(filters);
            const responseCountryState = await api.country.listStateByCountry('MX');
            //this.isListLoaded = true;
            this.changeLoader(true);
            this.customers = responseCustomer.data;
            this.countryStates = responseCountryState.data;
        } catch (ex) {
            this.addError(String(ex))
            console.error(ex);
        }
    }



    editCustomerEvent = (customer: ICustomer | null) => {
        this.editCustomer = true;
        this.selectedCustomer = customer;
    }


    cancelEditEvent = () => {
        this.selectedCustomer = null;
        this.editCustomer = false;
    }


     saveCustomerEvent = async (customer: ICustomer) => {
        if (customer?.id === 0) {
           const response = await api.customer.create(customer);
            this.customers.push(response.data);
        } else {
            const response = await api.customer.update(customer);
            let index = this.customers.findIndex((c) => c.id === customer.id);
            if (index !== -1) {
                this.customers[index] = customer;
            }
        }

        this.editCustomer = false;
        this.selectedCustomer = null;
    }



}
