import {makeAutoObservable} from 'mobx';

export default class MessageStore {

    show: boolean = false;
    message: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    showMessage = (message: string) => {
        this.message = message;
        this.show = true;
    }

    hideMessage = () => {
        this.show = false;
    }


}
