import {observer} from 'mobx-react-lite';
import {useStore} from '../../store/store';

export default observer(function StatusMessage() {
    const { messageStore } = useStore();
    if (!messageStore.show) return null;
    return (
        <div className="alert alert-secondary" role="alert">
            {messageStore.message}
        </div>
    )
});
