import { createContext, useContext } from 'react';
import CustomerStore from './customer-store';
import ServiceStore from './service-store';
import LoaderStore from './loader-store';
import MessageStore from './message-store';
interface IStore {
    customerStore: CustomerStore,
    serviceStore: ServiceStore,
    loaderStore: LoaderStore,
    messageStore: MessageStore
}

export const store: IStore = {
    customerStore: new CustomerStore(),
    serviceStore: new ServiceStore(),
    loaderStore: new LoaderStore(),
    messageStore: new MessageStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}
