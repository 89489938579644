import {observer} from 'mobx-react-lite';
import React from 'react';
import {useStore} from '../../store/store';

export default observer(function deleteModal () {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { customerStore } = useStore();
    const { deleteModalVisible, selectedCustomer, cancelDeleteCustomerEvent, errorMessage, confirmDeleteCustomerEvent } = customerStore;

    if (deleteModalVisible) {
        return (
            <React.Fragment>

                <div className="modal modal-sheet d-block  py-5 bg-drop" role="dialog"
                     id="modalSheet">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content rounded-6 shadow">
                            <div className="modal-header border-bottom-0">
                                <h5 className="modal-title">Delete customer</h5>
                                <button onClick={() => cancelDeleteCustomerEvent()} type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close">

                                </button>
                            </div>
                            <div className="modal-body py-0">
                                <p>Are you sure you want to eliminate <b>{selectedCustomer?.name}</b>?, it is an irreversible action.</p>

                                { errorMessage?.length !== 0 ? ( <div className="alert alert-danger" role="alert">
                                    {errorMessage}
                                </div>) : null }


                            </div>
                            <div className="modal-footer flex-column border-top-0">
                                <button onClick={() => confirmDeleteCustomerEvent()} type="button" className="btn btn-lg btn-danger w-100 mx-0 mb-2">Delete
                                </button>
                                <button onClick={() => cancelDeleteCustomerEvent()} type="button" className="btn btn-lg btn-light w-100 mx-0"
                                        data-bs-dismiss="modal">Cancel
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }  else {
        return null
    }


})
