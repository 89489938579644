import {makeAutoObservable, runInAction} from 'mobx';
import IService from '../entities/service';
import api from '../api/api';

export default class ServiceStore {

    isListLoaded: boolean = false;
    services: IService[] = [];
    editService: boolean = false;
    selectedService: IService | null = null;
    deleteModalVisible: boolean = false;
    errorMessage: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    changeLoader = (value: boolean) => {
        this.isListLoaded = value;
    }

    loadServices = async (filters: Partial<IService> = {}) => {
        try {
            let response;
            if (Object.keys(filters).length === 0) {
                response = await api.service.list();
            } else {
                response = await api.service.search(filters);
            }

            this.services = response.data;
        } catch (ex) {
            this.errorMessage = 'An error has occurred please try again later';
            console.log(ex);
        } finally {
            this.changeLoader(true);
        }
    }

    editServiceEvent = (service: IService | null) => {
        this.editService = true;
        this.selectedService = service;
    }

    cancelEditServiceEvent = () => {
        this.editService = false;
        this.selectedService = null;
        this.errorMessage = '';
    }

    saveServiceEvent = async (service: IService, isNew: boolean = false) => {
        try {

            if (isNew) {
               const response = await api.service.create(service);
               this.services.push(response.data);
            } else {
                const response = await api.service.update(service);
                let index = this.services.findIndex(item => item.id === service.id);
                if (index !== -1) {
                    this.services[index] = response.data;
                }
            }

            this.editService = false;
            this.selectedService = null;

        } catch (ex) {
            this.errorMessage = 'An error occurred, please try again.'
            console.log(ex);
        } finally {
            this.editService = false;
            this.selectedService = null;
        }
    }


    cancelDeleteServiceEvent = () => {
        runInAction(() => {
            this.deleteModalVisible = false;
            this.selectedService = null;
        });
    }

    deleteServiceEvent = (service: IService) => {
        this.deleteModalVisible = true;
        this.selectedService = service;
    }

    confirmDeleteServiceEvent = async () => {

        try {
            const response = await api.service.delete(this.selectedService!.id);
            if (response.data) {
                let serviceIndex = this.services.findIndex((service) => service.id === this.selectedService!.id );
                if (serviceIndex !== -1) {
                    this.services.splice(serviceIndex, 1);
                }
                this.cancelDeleteServiceEvent();
            } else {
                this.errorMessage = 'An error has occurred please try again later';
            }
        } catch (ex) {
            this.errorMessage = 'An error has occurred please try again later';
        }


    }


}
