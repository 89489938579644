import React, {useState} from 'react';
import ICustomer from '../../entities/customer';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../store/store';




export default observer(function CustomerEdit() {
    const { customerStore } = useStore();
    const { selectedCustomer, saveCustomerEvent, cancelEditEvent, countryStates } = customerStore;


    let defaultState = {
        code: '',
        countryCode: '',
        name: '',
        taxAuthorityCode: ''
    }
    let defaultCustomer: ICustomer = {
        id: 0,
        name: '',
        rfc: '',
        phone: '',
        email: '',
        address: '',
        stateCode: '',
        state: defaultState
    };
    let customerRef: ICustomer = (selectedCustomer !== null) ? selectedCustomer : defaultCustomer;
    let label = customerRef.id === 0 ? 'New Customer' : 'Edit customer';
    const [customer, setCustomer] = useState<ICustomer>(customerRef);

    const handleInputChange = (event: any) => {
        const {name, value} = event.target;
        setCustomer({...customer, [name]: value});
    }

    const handleCountryStateChange = (event: any) => {
        const {value} = event.target;
        setCustomer({...customer, stateCode: value});
    }

    return (
        <React.Fragment>
            <h3 className="mt-3 mb-3">{label}</h3>
            <form>
                <div className="mb-3">
                    <label  className="form-label">Name</label>
                    <input type="text" className="form-control" value={customer.name} onChange={handleInputChange} id="name" name="name"/>
                </div>
                <div className="mb-3">
                    <label className="form-label">RFC</label>
                    <input type="text" className="form-control" id="rfc" name="rfc" value={customer.rfc} onChange={handleInputChange}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Phone</label>
                    <input type="text" name="phone" className="form-control" id="phone" value={customer.phone} onChange={handleInputChange}/>
                </div>

                <div className="mb-3">
                    <label className="form-label">Address</label>
                    <input type="text" name="address" className="form-control" id="address" value={customer.address} onChange={handleInputChange}/>
                </div>

                <div className="mb-3">
                    <label className="form-label">State</label>
                    <select name="state" id="state" value={customer.stateCode} className="form-control" onChange={handleCountryStateChange}>
                        {
                            countryStates.map((state) => (
                                <option key={state.code} value={state.code}> {state.name} </option>
                            ))
                        }
                    </select>
                </div>

                <button type="button" onClick={() => cancelEditEvent()} className="btn btn-secondary">Cancel</button>
                <button type="button" onClick={() => saveCustomerEvent(customer) } className="btn btn-primary">Save</button>
            </form>
        </React.Fragment>
    );
});
