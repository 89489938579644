import React from 'react';
import Navigation from './components/navigation/navigation';
import Customer from './containers/customer';
import Service from './containers/service';

import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './containers/home';

import './styles/navbar-top-fixed.css';

export default function App() {
    return (
        <BrowserRouter>
            <Navigation/>
            <main className="container">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/customers" element={<Customer/>}/>
                    <Route path="/services"  element={<Service/>}/>
                </Routes>
            </main>
        </BrowserRouter>
    );
}
