import {observer} from 'mobx-react-lite';
import {useStore} from '../../store/store';
import React, {useState} from 'react';
import ServiceList from './service-list';
import ServiceEdit from './service-edit';

export default observer(function ServiceDashboard() {


    const { serviceStore } = useStore();
    const { editService, isListLoaded } = serviceStore;
    if (serviceStore.errorMessage.length > 0) {
        return (<div className="alert alert-danger">
            An error occurred, please try again: {serviceStore.errorMessage}
        </div>)
    }
    return (
        <React.Fragment>
            {!editService && isListLoaded && <ServiceList />}
            {editService && <ServiceEdit />}
            { !isListLoaded && <div>Loading...</div> }
        </React.Fragment>
    );
});
