import React, {useState} from 'react';
import ICustomer from '../../entities/customer';
import {useStore} from '../../store/store';
import {observer} from 'mobx-react-lite';
import ICustomerFilter from '../../entities/http/customer-filter';
import IState from '../../entities/state';

interface IProps {
    editCustomerEvent: (customer: ICustomer | null) => void;
    customers: ICustomer[];
}


export default observer(function CustomerList( ) {
    const { customerStore } = useStore();
    const { editCustomerEvent, customers, deleteCustomerEvent, loadCustomer, countryStates } = customerStore;
    const [ filter, setFilters ] = useState<ICustomerFilter>({ name: '' });
    const handleInputChange = (ev: any) => {
        const {name, value} = ev.target;
        setFilters({...filter, [name]: value});
    }

    let defaultState: IState = {
        code: '',
        countryCode: '',
        name: '',
        taxAuthorityCode: ''
    }
    const [state, setState] = useState<IState>(defaultState);



    const handleCountryStateChange = (event: any) => {
        const {value} = event.target;
        setState({...state, code: value});
        setFilters({...filter, stateCode: value});
    }

    return (
        <React.Fragment>

            <h3 className="mt-3 mb-3">Customers</h3>

            <div className="row mb-3">
                <div className="input-group">
                    <input id="name" name="name" value= {filter.name} onChange={handleInputChange} type="text" className="form-control" placeholder="Name or RFC" aria-label="First name"/>
                    <select name="state" id="state" value={state.code} className="form-control" onChange={handleCountryStateChange}>
                        <option key="all" value="">Todos</option>
                        {
                            countryStates.map((state) => (
                                <option key={state.code} value={state.code}> {state.name} </option>
                            ))
                        }
                    </select>
                    <button  onClick={ () => loadCustomer(filter) } className="btn btn-primary input-group-btn">Search</button>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-12 text-right">
                    <button onClick={ () => editCustomerEvent(null) } type="button" className="btn btn-success">
                        New Customer
                    </button>
                </div>
            </div>

            <table className="table">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">RFC</th>
                    <th scope="col">State</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>

                { customers.map((customer) => {
                    return (<tr key={customer.id.toString()} >
                        <th scope="row"> {customer.id} </th>
                        <td> { customer.name } </td>
                        <td> { customer.rfc } </td>
                        <td> { customer.state?.name } </td>
                        <td>
                            <button type="button" className="btn btn-primary" onClick={() => editCustomerEvent(customer)}>Edit</button>
                            <button type="button" className="btn btn-danger" onClick={() => deleteCustomerEvent(customer)}>Delete</button>
                        </td>
                    </tr>)
                })}



                </tbody>
            </table>
        </React.Fragment>
    );
});
