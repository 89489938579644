import {observer} from 'mobx-react-lite';
import React from 'react';
import {useStore} from '../../store/store';

export default observer(function StatusLoader() {

    const { loaderStore } = useStore();
    const { visible, title, message } = loaderStore;

  if (!visible) {
    return null;
  }

   return (<div className="loader bg-loader">
       <div className="modal-dialog" role="document">
           <div>
               <div className="loader-spinner">
                   <div className="spinner-border" role="status">
                       <span className="visually-hidden">Cargando...</span>
                   </div>
               </div>
               <div className="mt-4 border-bottom-0 text-center">
                   <h5 className="modal-title">{ title }</h5>
               </div>
               <div className="modal-body mt-2 py-0">
                   <p>{message}</p>
               </div>
           </div>
       </div>
   </div>)
});
