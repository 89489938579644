import {observer} from 'mobx-react-lite';
import {useStore} from '../../store/store';
import React, {useState} from 'react';
import IService from '../../entities/service';

export default observer(function ServiceEdit() {

    const { serviceStore, loaderStore } = useStore();
    const { selectedService, saveServiceEvent, cancelEditServiceEvent } = serviceStore;


    let defaultService: IService = {
        id: '',
        description: ''
    };
    let isNewService: boolean = selectedService === null;
   let serviceRef: IService = (selectedService !== null) ? selectedService : defaultService;
    let label = serviceRef.id === '' ? 'New Service' : 'Edit Service';
    const [service, setService] = useState(serviceRef);

    const handleChange = (event: any) => {
        setService({...service, [event.target.name]: event.target.value});
    }

    const saveService = () => {
        loaderStore.show('Saving Service...', 'please wait while we save your service');
        saveServiceEvent(service, isNewService).then(() => {
            loaderStore.setStatus(false);
        });
    }

    return (
        <React.Fragment>
            <h3 className="mt-3 mb-3">{label}</h3>
            <form>
                <div className="mb-3">
                    <label  className="form-label">Id</label>
                    <input disabled={!isNewService} type="text" className="form-control" value={service.id} onChange={handleChange} id="id" name="id"/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Description</label>
                    <textarea className="form-control" id="description" name="description" value={service.description} onChange={handleChange}/>
                </div>


                <button type="button" onClick={() => cancelEditServiceEvent()} className="btn btn-secondary">Cancel</button>
                <button type="button" onClick={() => saveService() } className="btn btn-primary">Save</button>
            </form>
        </React.Fragment>
    );

});
