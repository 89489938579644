import React, {useEffect, useState} from 'react';
import CustomersDashboard from '../components/customers/customer-dashboard';
import {useStore} from '../store/store';
import {observer} from 'mobx-react-lite';
import DeleteCustomerModal from '../components/modal/delete-modal';

export default observer(function Customer() {

    const { customerStore } = useStore();


    useEffect(  () => {
      customerStore.loadCustomer().then(r => {});
    }, [customerStore]);

    return (
        <React.Fragment>
            <DeleteCustomerModal />
            <div>
                <h1>Customer Dashboard</h1>
                <CustomersDashboard />
            </div>
        </React.Fragment>

    );
});
