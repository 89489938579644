import {observer} from 'mobx-react-lite';
import {useStore} from '../../store/store';
import React, {useState} from 'react';
import StatusMessage from '../status/status-message';

export default observer(function ServiceList() {

    const { serviceStore, loaderStore, messageStore } = useStore();
    const { editServiceEvent, services, deleteServiceEvent, loadServices } = serviceStore;

    const [search, setSearch] = useState<string>('');
    const handleInputSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const searchServices =  () => {
        loaderStore.show(search.length === 0 ? 'Getting services' : 'Searching ' + search, 'Please wait...');
        loadServices({id: search, description: search}).then(() => {
            loaderStore.setStatus(false);
        }).then(() => {
            if (services.length === 0) {
                messageStore.showMessage('No services found');
            }
        });
    };

    return (
        <React.Fragment>

            <h3 className="mt-3 mb-3">Services</h3>

            <div className="row mb-3">
                <div className="input-group">
                    <input id="name" name="name" value= {search} onChange={handleInputSearchChange} type="text" className="form-control" placeholder="Id or Description" aria-label="First name"/>
                    <button  onClick={ () => searchServices() } className="btn btn-primary input-group-btn">Search</button>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-12 text-right">
                    <button onClick={ () => editServiceEvent(null) } type="button" className="btn btn-success">
                        New service
                    </button>
                </div>
            </div>

            { services.length === 0 && <StatusMessage /> }


            { services.length !== 0 && <table className="table">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Description</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>

                { services.map((service) => {
                    return (<tr key={service.id.toString()} >
                        <th scope="row"> {service.id} </th>
                        <td> { service.description } </td>
                        <td>
                            <button type="button" className="btn btn-primary" onClick={() => editServiceEvent(service)}>Edit</button>
                            <button type="button" className="btn btn-danger" onClick={() => deleteServiceEvent(service)}>Delete</button>
                        </td>
                    </tr>)
                })}



                </tbody>
            </table> }
        </React.Fragment>
    );
});
