import React from 'react';
import CustomerList from './customer-list';
import CustomerEdit from './customer-edit';
import {useStore} from '../../store/store';
import {observer} from 'mobx-react-lite';

export default observer(function CustomersDashboard() {

    const { customerStore } = useStore();
    const { editCustomer, isListLoaded } = customerStore;
    if (customerStore.errorMessage.length > 0) {
        return (<div className="alert alert-danger">
            An error occurred, please try again: {customerStore.errorMessage}
        </div>)
    }
    return (
        <React.Fragment>
            {!editCustomer && isListLoaded && <CustomerList />}
            {editCustomer && <CustomerEdit />}
            { !isListLoaded && <div>Loading...</div> }
        </React.Fragment>
    );
});
